<template>
	<div>
		<div class="_w-max" style="background-color: #f5f5f5;">
			<img style="width: 100%;" :src="agent_info.issue_banner.url"/>
		</div>
		<div class="_w-max _pc-pad-x-280 _pc-pad-y-65 mb-pad-x-15"  style="background-color: #f5f5f5;">
			<div class="main">
				<div class="item-box _pc-pad-x-30" v-for="(item,index) in list" :key="index" @click="getDetail(item.id)">
					<div class="item">
						<div class="title">{{item.title}}</div>
						<div class="iconfont2 icon-jiantou"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { articleList, bannerList } from '@/api/jiyun';
	export default {
		data() {
			return {
				title:'常见问题',
				keyWords:'航达',
				description:``,
				list :[],
				agent_info: {},
			}
		},
		created() {
			this.getList();
			this.bannerList();
		},
		metaInfo() {
			return {
				title: this.title,
				meta: [
					{
						name: "keyWords",
						content: this.keyWords,
					},
					{
						name: "description",
						content: this.description,
					},
				],
			};
		},
		methods: {
			// 代理banner
			bannerList(){
				let param = {
					domain_url: window.location.origin
				}
				bannerList(param).then((res)=>{
					this.agent_info = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 列表
			getList(){
				let param = {
					cid: 2,
					domain_url: window.location.origin
				}
				articleList(param).then((res)=>{
					this.list = res.data
				}).catch((err)=>{
					this.$Message.error(err.msg);
				})
			},
			// 详情
			getDetail(id) {
				this.$router.push({
					path:'/question_detail',
					query:{
						id:id
					}
				})
			}
		}
	}
</script>

<style scoped>
	@media screen and (min-width: 768px){
		.main {
			width: 100%;
			border-radius: 17px;
			overflow: hidden;
		}
		.main .item-box {
			width: 100%;
			height: 83px;
			background-color: #ffffff;
		}
		.main .item-box:hover {
			background-color: rgba(66, 132, 220, 0.1);	
		}
		.main .item-box .item {
			width: 100%;
			height: 100%;
			border-bottom: 1px solid #e2e2e2;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.main .item-box:last-child .item {
			border-bottom: none;
		}
		.main .item-box .item:hover {
			border-bottom: none;
		}
		.main .item-box .item .title {
			color: #333;
			font-size: 24px;
		}
		.main .item-box .item .icon-jiantou {
			color: #999;
			font-size: 16px;
		}
		.main .item-box .item:hover .title {
			color: #4284DC;
		}
		.main .item-box .item:hover .icon-jiantou {
			color: #4284DC;
		}
	}
	
	/*屏幕在480px到768之间（主要是手机屏幕）*/
	@media screen and (max-width: 768px){
		input {
			outline: none;
		}
		.display-none {
			display: none !important;
		}
		.mb-display-block {
			display: block;
		}
		/* pad */
		.mb-pad-0 {
			padding: 0 !important;
		}
		.mb-pad-x-15 {
			padding-left: 0.9375rem !important;
			padding-right: 0.9375rem !important;
		}
		.mb-pad-x-25 {
			padding-left: 1.5625rem;
			padding-right: 1.5625rem;
		}
		.mb-pad-x-30 {
			padding-left: 1.875rem;
			padding-right: 1.875rem;
		}
		.mb-pad-y-15 {
			padding-top: 0.9375rem !important;
			padding-bottom: 0.9375rem !important;
		}
		.mb-pad-y-25 {
			padding-top: 1.5625rem;
			padding-bottom: 1.5625rem;
		}
		.mb-pad-y-30 {
			padding-top: 1.875rem;
			padding-bottom: 1.875rem;
		}
		/* mar */
		.mb-mar-0 {
			margin: 0;
		}
		.mb-mar-top-30 {
			margin-top: 1.875rem;
		}
		.mb-mar-top-50 {
			margin-top: 3.125rem;
		}
		.mb-mar-right-15 {
			margin-right: 0.9375rem;
		}
		.mb-mar-y-15 {
			margin-top: 0.9375rem;
			margin-bottom: 0.9375rem;
		}
		.mb-mar-y-25 {
			margin-top: 1.5625rem;
			margin-bottom: 1.5625rem;
		}
		/* width */
		.mb-w-max {
			width: 100%;
		}
		.mb-w-70 {
			width: 4.375rem;
		}
		.mb-w-100 {
			width: 6.25rem;
		}
		.mb-w-18 {
			width: 1.125rem;
		}
		/* height */
		.mb-h-60 {
			height: 3.75rem;
		}
		/* font */
		.mb-font-15 {
			font-size: 0.9375rem !important;
		}
		.mb-font-20 {
			font-size: 1.25rem;
		}
		.mb-font-25 {
			font-size: 1.5625rem;
		}
		.mb-font-30 {
			font-size: 1.875rem;
		}
		.mb-font-35 {
			font-size: 2.1875rem;
		}
		
		.mb-text-center {
			text-align: center;
		}
		/* flex */
		.mb-flex-1 {
			flex: 1;
		}
		.mb-flex-2 {
			flex: 2;
		}
		.mb-flex-col-center-mid {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		.mb-flex-row-bet-mid {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
		.mb-flex-row-mid {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		.mb-flex-shrink {
			flex-shrink: 0;
		}
		.mb-border-radius-10 {
			border-radius: 0.625rem;
		}
		.mb-border-radius-15 {
			border-radius: 0.9375rem;
		}
		.mb-grid-column-max-span{
			grid-column: 1 / -1;
		}
		.mb-order-1 {order: 1;}
		.mb-order-2 {order: 2;}
		.mb-order-3 {order: 3;}
		.mb-order-4 {order: 4;}
		.mb-order-5 {order: 5;}
		.mb-order-6 {order: 6;}
		.mb-order-7 {order: 7;}
		.mb-order-8 {order: 8;}
		.mb-order-9 {order: 9;}
		.mb-order-10 {order: 10;}
		.mb-min-height-205 {
			min-height: 205px;
		}
		.main {
			width: 100%;
			border-radius: 0.625rem;
			overflow: hidden;
			padding: 1.5625rem 0;
		}
		.main .item-box {
			width: 100%;
			background-color: #ffffff;
			padding: 0rem 0.5rem;
		}
		.main .item-box .item {
			width: 100%;
			height: 100%;
			border-bottom: 1px solid #f5f5f5;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0.75rem 0rem;
		}
		.main .item-box:last-child .item {
			border-bottom: none;
		}
		.main .item-box .item:hover {
			border-bottom: none;
		}
		.main .item-box .item .title {
			color: #333;
			font-size: 0.9375rem;
		}
		.main .item-box .item .icon-jiantou {
			color: #999;
			font-size: 0.75rem;
		}
		.main .item-box .item:hover .title {
			color: #4284DC;
		}
		.main .item-box .item:hover .icon-jiantou {
			color: #4284DC;
		}
	}
</style>